export interface TClient {
  number: number;
  name: string;
  image_url: string;
  image_width: number;
  image_bigger_width?: number;
  is_has_bigger_width?: boolean;
}

import ADB from '../../assets/pages/clients/svg/adb.svg';
import TrueDigital from '../../assets/pages/clients/svg/true-digital.svg';
import Kasikorn from '../../assets/pages/clients/svg/kasikorn.svg';
import NIA from '../../assets/pages/clients/svg/nia.svg';
import TCEB from '../../assets/pages/clients/svg/tceb.svg';
import Promaid from '../../assets/pages/clients/svg/promaid.svg';
import SabuyWedding from '../../assets/pages/clients/svg/sabuywedding.svg';
import TaiwanTourism from '../../assets/pages/clients/svg/taiwan-tourism.svg';
import SAS from '../../assets/pages/clients/svg/sas.svg';
import ChulalongkornUniversity from '../../assets/pages/clients/svg/chula.svg';
import AmazingThailand from '../../assets/pages/clients/svg/amazing-thailand.svg';
import TAT from '../../assets/pages/clients/svg/tat.svg';
import WashXpress from '../../assets/pages/clients/svg/washxpress.svg';
import TagThai from '../../assets/pages/clients/svg/tagthai.svg';
import TakeMeTour from '../../assets/pages/clients/svg/takemetour.svg';
import LocalCommunity from '../../assets/pages/clients/svg/local-community.svg';
import LocalTable from '../../assets/pages/clients/svg/local-table.svg';
import Wendays from '../../assets/pages/clients/svg/wendays.svg';
import Gunkul from '../../assets/pages/clients/svg/gunkul.svg';
import DNACanvas from '../../assets/pages/clients/svg/dna-canvas.svg';
import EasyCompany from '../../assets/pages/clients/svg/easycompany.svg';
import EasyPDPA from '../../assets/pages/clients/svg/easypdpa.svg';
import EasyCookies from '../../assets/pages/clients/svg/easycookies.svg';
import LocalFarm from '../../assets/pages/clients/svg/local-farm.svg';
import FortuneTown from '../../assets/pages/clients/svg/fortune-town.svg';

export const Clients: TClient[] = [
  {
    number: 1,
    name: 'ADB Ventures',
    image_url: ADB,
    image_width: 100,
  },
  {
    number: 2,
    name: 'True Digital',
    image_url: TrueDigital,
    image_width: 65,
  },
  {
    number: 3,
    name: 'Kasikorn Bank',
    image_url: Kasikorn,
    image_width: 120,
  },
  {
    number: 4,
    name: 'NIA',
    image_url: NIA,
    image_width: 90,
  },
  {
    number: 5,
    name: 'TCEB',
    image_url: TCEB,
    image_width: 64,
  },
  {
    number: 6,
    name: 'Promaid',
    image_url: Promaid,
    image_width: 100,
  },
  {
    number: 7,
    name: 'Sabuywedding',
    image_url: SabuyWedding,
    image_width: 75,
  },
  {
    number: 8,
    name: 'Taiwan Tourism',
    image_url: TaiwanTourism,
    image_width: 110,
  },
  {
    number: 9,
    name: 'SAS',
    image_url: SAS,
    image_width: 90,
  },
  {
    number: 10,
    name: 'Chulalongkorn University',
    image_url: ChulalongkornUniversity,
    image_width: 120,
  },
  {
    number: 11,
    name: 'Amazing Thailand',
    image_url: AmazingThailand,
    image_width: 95,
  },
  {
    number: 12,
    name: 'TAT',
    image_url: TAT,
    image_width: 68,
  },
  {
    number: 13,
    name: 'WashXpress',
    image_url: WashXpress,
    image_width: 130,
  },
  {
    number: 14,
    name: 'TagThai',
    image_url: TagThai,
    image_width: 70,
  },
  {
    number: 15,
    name: 'TakeMeTour',
    image_url: TakeMeTour,
    image_width: 150,
  },
  {
    number: 16,
    name: 'Local Community',
    image_url: LocalCommunity,
    image_width: 120,
  },
  {
    number: 17,
    name: 'Local Table',
    image_url: LocalTable,
    image_width: 96,
  },
  {
    number: 18,
    name: 'Wendays',
    image_url: Wendays,
    image_width: 160,
  },
  {
    number: 19,
    name: 'Gunkul',
    image_url: Gunkul,
    image_width: 120,
  },
  {
    number: 20,
    name: 'DNA Canvas',
    image_url: DNACanvas,
    image_width: 110,
  },
  {
    number: 21,
    name: 'EasyCompany',
    image_url: EasyCompany,
    image_width: 150,
    image_bigger_width: 200,
    is_has_bigger_width: true,
  },
  {
    number: 22,
    name: 'EasyPDPA',
    image_url: EasyPDPA,
    image_width: 110,
    image_bigger_width: 130,
    is_has_bigger_width: true,
  },
  {
    number: 23,
    name: 'EasyCookies',
    image_url: EasyCookies,
    image_width: 120,
    image_bigger_width: 200,
    is_has_bigger_width: true,
  },
  {
    number: 24,
    name: 'Local Farm',
    image_url: LocalFarm,
    image_width: 100,
    image_bigger_width: 150,
    is_has_bigger_width: true,
  },
  {
    number: 25,
    name: 'Fortune Town',
    image_url: FortuneTown,
    image_width: 60,
  },
];
