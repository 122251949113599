import React from 'react';
import { Clients } from '../../../utils/contents/clients';
import { Client, ClientsRow } from './OurClientSection';

const OurClientsSwiperPage2: React.FC<{ screenWidth: number; }> = ({ screenWidth }) => {
  const secondPageRow1 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 13 && client.number <= 15;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 15 && client.number <= 18;
    return client.number >= 15 && client.number <= 20;
  });

  const secondPageRow2 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 16 && client.number <= 19;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 19 && client.number <= 22;
    return client.number >= 21;
  });

  const secondPageRow3 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 20 && client.number <= 22;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 23;
    return client.number >= 8 && client.number <= 14; // third row will not appear on >= 1024 width so this return will not be shown
  });

  const secondPageRow4 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 23;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 11 && client.number <= 14; // this row wont appear since all images length <= 25
    return client.number >= 8 && client.number <= 14; // fourth row will not appear on >= 1024 width so this return will not be shown
  });

  return (
    <div className="w-full flex flex-col gap-y-8">
      {/* 1st row*/}
      <div>
        <ClientsRow className="gap-x-6 justify-evenly">
          {secondPageRow1.map((client) => (
            <Client
              key={client.number}
              data={client}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>
      {/* 2st row */}
      <div>
        <ClientsRow className="gap-x-3 justify-evenly">
          {secondPageRow2.map((client) => (
            <Client
              key={client.number}
              data={client}
              required_bigger_image_size={client.is_has_bigger_width}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>
      {/* 3rd row */}
      <div className="w-full flex justify-center lg:hidden">
        <ClientsRow className="gap-x-6 md:gap-x-0 justify-evenly">
          {secondPageRow3.map((client) => (
            <Client
              key={client.number}
              data={client}
              required_bigger_image_size={client.is_has_bigger_width}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>
      {/* 4th row */}
      <div className="w-full flex justify-center tablet-vertical:hidden">
        <ClientsRow className="gap-x-6 justify-evenly">
          {secondPageRow4.map((client) => (
            <Client
              key={client.number}
              data={client}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>
    </div>
  );
};

export default OurClientsSwiperPage2;
