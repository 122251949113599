import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Clients, TClient } from '../../../utils/contents/clients';
import { Navigation, Pagination } from 'swiper';
import { SectionText } from '../../../utils/TextLabel';
import TrustedClients1 from '../../../assets/pages/index/TrustedClients1.svg';
import TrustedClients2 from '../../../assets/pages/index/TrustedClients2.svg';
import OurClientsSwiperPage1 from './OurClientsSwiperPage1';
import OurClientsSwiperPage2 from './OurClientsSwiperPage2';
import useOnMount from '../../../hooks/use-on-mount';

// const StyledContainer = styled.section(() => [tw`relative flex flex-col items-center mx-auto mb-16 md:mb-18`]);
const StyledContainer = styled.section(() => [tw`relative bg-[#F3F3F4] w-full py-10 md:py-12`]);

const InnerContainer = styled.div`
  ${tw`w-full mx-auto md:max-w-pageContainerSize px-pageContainerSpace xl:px-0`}
  ${tw`grid items-center justify-around grid-cols-2 gap-6 md:gap-1 md:flex md:flex-wrap`}
  @media (max-width: 767px) {
    *:nth-last-child(1) {
      ${tw`col-span-2`}
    }
    *:nth-child(even) {
      ${tw`col-span-1`}
    }
  }
`;

const Logo = styled.img`
  ${tw`max-w-full h-[45px] md:h-[50px] lg:h-[70px] md:max-w-[180px] lg:max-w-[250px] object-scale-down`}
`;

const LogoContainer = styled.div`
  ${tw`flex items-center justify-center m-0 overflow-hidden md:m-6 xl:m-8`}
`;

// const SectionText = styled.p(() => [
//   tw`flex w-full text-[20px] lg:text-[32px] justify-center font-bold text-[#283941] pt-8 md:pt-10 md:mb-16 mb-12`
// ]);

const ClientsIconContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
  justify-content: center;
  @media only screen and (min-width: 425px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  @media only screen and (min-width: 1024px) {
    gap: 4rem;
  }
  @media only screen and (min-width: 1200px) {
    gap: 3rem;
  }
`;

// export for separated clients page
export const ClientsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

// const SwiperSlide = styled(_SwiperSlide)`
//   margin-bottom: 1px;
// `;

interface TRenderClient {
  data: TClient;
  required_bigger_image_size?: boolean;
  screen_width: number;
}

export const Client: React.FC<TRenderClient> = ({ data, required_bigger_image_size, screen_width }) => {
  return (
    <div className="flex flex-col items-center justify-center w-auto h-auto">
      <img
        alt={`Client ${data.name}`}
        src={data.image_url}
        width={(screen_width >= 768 && required_bigger_image_size) ? data.image_bigger_width : data.image_width}
        className="object-cover"
        loading="lazy"
      // className={`object-contain ${data.name.includes('Easy') ? 'w-20 md:w-28 xl:w-32' : 'w-[80px] lg:w-[90px]'}`}
      />
    </div>
  );
};

const OurClientSection: React.FC = () => {
  const [screenWidth, setScreenWidth] = useState(0);
  const isMount = useOnMount();
  // TODO We comment this data because we want to fix the order of logo
  // const data = useStaticQuery(graphql`
  //   query OurClientSectionQuery {
  //     allStrapiClients {
  //       edges {
  //         node {
  //           Icon {
  //             localFile {
  //               publicURL
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  useEffect(() => {
    // set screen width to be screen at first time
    if (screenWidth === 0) {
      setScreenWidth(window.innerWidth);
    }
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledContainer>
      <SectionText className="mx-auto">Trusted by our clients</SectionText>
      <div className="w-[92%] xl:w-[1200px] mx-auto mt-14 relative overflow-x-auto">
        {isMount && (
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              320: {
                navigation: false,
                spaceBetween: 10,
              },
              768: {
                navigation: true,
                spaceBetween: 16,
              },
              900: {
                navigation: true,
                spaceBetween: 20,
              }
            }}
          >
            <SwiperSlide className="mb-14">
              <OurClientsSwiperPage1 screenWidth={screenWidth} />
            </SwiperSlide>
            <SwiperSlide className="mb-14">
              <OurClientsSwiperPage2 screenWidth={screenWidth} />
            </SwiperSlide>
          </Swiper>
        )}
        {/* <Swiper
          modules={[Navigation, Pagination]}
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={10}
          className="px-2 pt-4 md:px-4 pb-14 xl:py-10"
          breakpoints={{
            320: {
              navigation: false,
              spaceBetween: 10,
            },
            768: {
              navigation: true,
              spaceBetween: 16,
            },
            900: {
              navigation: true,
              spaceBetween: 20,
            }
          }}
        >
          <SwiperSlide>
            <img
              alt="Clients Page 1"
              src={TrustedClients1}
              className="w-full"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              alt="Clients Page 2"
              src={TrustedClients2}
              className="w-full"
            />
          </SwiperSlide>
        </Swiper> */}
      </div>
    </StyledContainer>
  );
};

export default OurClientSection;
