import React from 'react';
import { Clients } from '../../../utils/contents/clients';
import { Client, ClientsRow } from './OurClientSection';

const OurClientsSwiperPage1: React.FC<{ screenWidth: number; }> = ({ screenWidth }) => {
  const firstPageRow1 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number <= 3;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number <= 5;
    return client.number <= 7;
  });

  const firstPageRow2 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 2 && client.number <= 5;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 6 && client.number <= 10;
    return client.number >= 8 && client.number <= 14;
  });

  const firstPageRow3 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 6 && client.number <= 9;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 11 && client.number <= 14;
    return client.number >= 8 && client.number <= 14; // third row will not appear on >= 1024 width so this return will not be shown
  });

  const firstPageRow4 = Clients.filter((client) => {
    if (screenWidth < 600) return client.number >= 10 && client.number <= 12;
    if (screenWidth >= 600 && screenWidth < 1024) return client.number >= 11 && client.number <= 14;
    return client.number >= 8 && client.number <= 14; // fourth row will not appear on >= 1024 width so this return will not be shown
  });

  return (
    <div className="w-full flex flex-col gap-y-8">
      {/* 1st row*/}
      <ClientsRow className="gap-x-3 lg:gap-x-2 justify-evenly">
        {firstPageRow1.map((client) => (
          <Client
            key={client.number}
            data={client}
            screen_width={screenWidth}
          />
        ))}
      </ClientsRow>

      {/* 2st row */}
      <ClientsRow className="gap-x-4 lg:gap-x-0 justify-evenly">
        {firstPageRow2.map((client) => (
          <Client
            key={client.number}
            data={client}
            screen_width={screenWidth}
          />
        ))}
      </ClientsRow>

      {/* 3rd row */}
      <div className="w-full flex justify-center lg:hidden">
        <ClientsRow className="gap-x-6 justify-evenly">
          {firstPageRow3.map((client) => (
            <Client
              key={client.number}
              data={client}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>

      {/* 4th row */}
      <div className="w-full flex justify-center tablet-vertical:hidden">
        <ClientsRow className="gap-x-8 justify-evenly">
          {firstPageRow4.map((client) => (
            <Client
              key={client.number}
              data={client}
              screen_width={screenWidth}
            />
          ))}
        </ClientsRow>
      </div>
    </div>
  );
};

export default OurClientsSwiperPage1;
